<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="4">
        <div class="center-logo">
          <app-logo></app-logo>
        </div>
        <v-card flat class="rounded-xl">
          <v-toolbar flat class="d-flex justify-center pt-2">
            <v-card-title class="bold-text capitalize">
              reset your password
            </v-card-title>
          </v-toolbar>
          <v-form lazy-validation ref="form" class="text-left px-10 pb-7">
            <label class="pl-4">Email</label>
            <v-text-field
              solo
              flat
              dense
              rounded
              background-color="secondary"
              :rules="validate.required('email')"
              placeholder="Email"
              type="email"
              v-model="form.email"
            >
              <template v-slot:prepend-inner>
                <v-icon class="pa-2 ml-n6 rounded-xl primary" color="white">
                  mdi-email-outline
                </v-icon>
              </template>
            </v-text-field>
            <label class="pl-4">Enter New Password</label>
            <v-text-field
              solo
              flat
              dense
              rounded
              background-color="#eff3ff"
              :rules="validate.required('password')"
              placeholder="Password"
              :type="!showPassword ? 'password' : 'text'"
              v-model="form.password"
            >
              <template v-slot:prepend-inner>
                <v-icon color="white" class="primary ml-n6 pa-2 rounded-xl">
                  mdi-lock-outline
                </v-icon>
              </template>
              <template v-slot:append>
                <v-icon
                  @click="showPassword = !showPassword"
                  color="grey"
                  class="pointer"
                >
                  {{
                    !showPassword ? "mdi-eye-outline" : "mdi-eye-off-outline"
                  }}
                </v-icon>
              </template>
            </v-text-field>
            <label class="pl-4">Repeat New Password</label>
            <v-text-field
              solo
              flat
              dense
              rounded
              background-color="#eff3ff"
              :rules="validate.required('password')"
              placeholder="Password"
              :type="!showPassword ? 'password' : 'text'"
              v-model="form.password_confirmation"
            >
              <template v-slot:prepend-inner>
                <v-icon color="white" class="primary ml-n6 pa-2 rounded-xl">
                  mdi-lock-outline
                </v-icon>
              </template>
            </v-text-field>
            <div class="justify-center d-flex pb-4">
              <v-btn
                text
                class="rounded-xl px-7 py-5 primary capitalize"
                @click="$refs.form.validate() ? resetPassword() : null"
                :loading="loading"
              >
                Submit
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppLogo from "../../components/Logo";
import FormValidation from "../../utils/FormValidation";
import { mapActions } from "vuex";
import ResponseHelper from "../../utils/ResponseHelper";
export default {
  name: "ForgotPassword",
  components: {
    AppLogo,
  },
  data() {
    return {
      form: {},
      validate: new FormValidation(),
      toast: new ResponseHelper(),
      loading: false,
      showPassword: false,
    };
  },
  created() {
    this.form.email = this.$route.query.email;
    this.form.token = this.$route.query.token;
  },
  methods: {
    ...mapActions("auth", ["reset_password"]),
    async resetPassword() {
      this.loading = true;
      try {
        const response = await this.reset_password(this.form);
        this.loading = false;
        await this.$router.replace({ name: "Login" });
        this.toast.sendSuccess(response);
      } catch (error) {
        this.loading = false;
        this.toast.sendError(error);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.center-logo {
  display: inline-flex;
}
.bold-text {
  font-weight: 900;
}
</style>
